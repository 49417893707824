.payment-box {
    background-color: var(--ion-color-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    align-content: center;
    color: #fff;
    text-align: center;
    padding: 2em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: inherit;
    }
}
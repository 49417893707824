.pop-up {
    display: none;
    position: absolute;
    bottom: 30px;
    font-size: 15px;
    background-color: #ffff;
    z-index: 9;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 1px 12.5px 0 rgba(0, 79, 250, 0.1);
    &.show {
        display: block;
    }
    .close-icon {
        text-align: right;
        padding-right: 13px;
        font-size: 18px;
        color: var(--ion-color-primary);
        margin: 5px 0 0;
        text-transform: uppercase;
        font-family: arial;
    }
}

.loading {
    background-color: var(--ion-color-primary);
    color: #fff;
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .looking-icon {
        width: 40px;
        margin-bottom: 30px;
    }
}

img.small-logo {
    max-width: 80px;
}
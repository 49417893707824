@import "react-datepicker/dist/react-datepicker.css";
@import 'react-datepicker/dist/react-datepicker-cssmodules.css';

:root {
    background-color: #fff;
}

body {
    background-color: #FFF;
}


ion-input {
    border: solid 0.5px rgba(23, 24, 81, 0.3);
    border-radius: 5px;
    line-height: 30px;
    input {
        padding-left: 10px !important;
    }
}

ion-content {
    --background: #fff;
    &.grey-bg {
        --background: #f4f6fc !important;
    }
    &.white-bg {
        --background: #fff !important;
    }
}

ion-item {
    --inner-padding-end: 0;
}

ion-button {
    margin: 0;
    --border-radius: 0px;
}

ion-slide {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
}

.capitalize {
    text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: #171851;
}

.spacer {
    height: 30px;
    &.spacer-blue {
        background-color: #004ffa;
    }
    &.spacer-sm {
        height: 10px !important;
    }
    &.spacer-lg {
        height: 50px !important;
    }
    &.spacer-xl {
        height: 80px !important;
    }
}

.font-light {
    font-weight: 300;
}

.font-medium {
    font-weight: 400;
}

.font-bold {
    font-weight: 600;
}

.font-bolder {
    font-weight: 700;
}

.font-black {
    font-weight: 800;
}

.loading {
    background-color: var(--ion-color-primary);
    color: #fff;
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div {
        display: flex;
        align-items: center;
    }
}

.spacer-blue {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #fff;
    }
}

.top-card {
    background-color: var(--ion-color-primary);
    height: 200px;
    color: #fff;
    padding-top: 30px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #fff;
    }
}

.ion-no-margin {
    margin: 0 !important;
}

.ion-padding {
    padding: 20px !important;
}

.detail-card {
    margin: 20px 20px 10px;
    &.first-card {
        margin: -100px 20px 10px;
    }
    background-color: #fff;
    border-radius: 2px;
    position: relative;
    padding: 20px;
    >div {
        position: relative;
    }
    .card-divider {
        height: 1px;
        background-color: #ccc;
        margin: 10px 0;
    }
    .small-logo-abs {
        position: absolute;
        right: 20px;
        top: 10px;
        width: 50px;
    }
    .gear-icon {
        margin-right: 20px;
        max-width: 30px;
    }
    h3 {
        font-size: 18px;
    }
    .service-badge {
        display: block;
        background-color: #ff4e00;
        color: #fff;
        padding: 5px;
        font-size: 10px;
        border-radius: 5px;
    }
}

.ion-float-end {
    float: right !important;
}

.ion-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.text-white {
    color: #fff;
}

.item-radio-checked {
    border: 1px solid var(--ion-color-primary);
    border-radius: 2px;
    --background: #fff !important;
}

.footer-background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: transparent;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

ion-toolbar {
    --background: transparent !important;
    --opacity: 1;
    .toolbar-background {
        background: transparent !important;
    }
}

ion-slide {
    overflow-y: auto;
}

.error-input ion-input {
    border: 1px solid var(--ion-color-danger);
    transition: all ease-in-out 0.4s;
}

.has-focus {
    border: 1px solid var(--ion-color-primary);
    transition: all ease-in-out 0.4s;
}

ion-input {
    font-size: 13px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #b2cafd;
    -webkit-text-fill-color: #333;
    -webkit-box-shadow: 0 0 0px 1000px #f4f6fc inset;
    transition: background-color 5000s ease-in-out 0s;
}

.btn-sm {
    border-radius: 2px;
    font-size: 12px;
    text-align: left;
    background-color: transparent;
    color: #004ffa;
    text-decoration: underline;
    background-image: none;
}

.white-bg {
    background-color: #fff;
}

.error-box {
    color: #fff;
    margin: 0 0 20px;
    background-color: var(--ion-color-danger);
    padding: 10px;
    border-radius: 4px;
}

ion-item.item-select {
    border: solid 0.5px rgba(23, 24, 81, 0.3);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 0 10px;
    line-height: 35px;
    color: #171851;
}

ion-select {
    border: solid 0.5px rgba(23, 24, 81, 0.3);
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 0 10px;
    line-height: 35px;
    color: #171851;
}

.select-placeholder {
    color: #171851;
    font-size: 10px;
}

.action-sheet-title.sc-ion-action-sheet-md {
    font-weight: 900;
    color: #171851;
    border-bottom: 1px solid #ccc;
}

.action-sheet-sub-title {
    color: #171851;
    font-weight: 300;
    padding-bottom: 30px;
}

button.action-sheet-button.ion-activatable.ion-focusable.action-sheet-.select-interface-option.ios.sc-ion-action-sheet-md {
    font-size: 12px;
    height: 40px;
    font-weight: 600;
    color: #555;
}

ion-select {
    --placeholder-color: #171851;
    border: solid 0.5px rgb(56, 128, 255);
    background-color: #fff;
    border-radius: 1px;
    margin-bottom: 15px;
    padding: 0 10px;
    line-height: 35px;
    color: #171851;
}

button.action-sheet-button.ion-activatable.ion-focusable.action-sheet-cancel.sc-ion-action-sheet-md {
    background-color: #ff4e00;
    color: #222;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    border-top: 1px solid #ccc;
}

.ion-page {
    background-color: #fff;
}

.select-icon {
    position: relative;
    opacity: 1;
}

.select-icon-inner {
    left: 5px;
    top: 50%;
    margin-top: -2px;
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 5px solid rgb(56, 128, 255);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    color: rgb(56, 128, 255);
    pointer-events: none;
    opacity: 1;
}

.select-text {
    flex: 1 1 0%;
    min-width: 16px;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    opacity: 1;
}

ion-action-sheet {
    --max-height: 80% !important;
}

.action-sheet-container {
    .action-sheet-group:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

label {
    font-size: 12px;
}
.circle-progress {
    margin: -40px 0 0 30px;
    width: 80px;
    &.white-bg {
        background-color: #fff;
        padding: 10px;
    }
}

ion-slides {
    height: 100%;
}

ion-slide {
    align-items: flex-start !important;
    justify-content: space-between !important;
}

div.ion-text-left,
ion-label {
    width: 100% !important;
    display: block !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
    input {
        width: 100%;
        background-color: #fff;
        line-height: 50px;
        border: 1px solid #efefef;
        border-radius: 3px;
        padding-left: 10px;
    }
}

.react-datepicker-popper {
    position: relative !important;
    transform: none !important;
    .react-datepicker {
        width: 100%;
        border-radius: 0 !important;
        border: 0 !important;
        .react-datepicker__triangle {
            display: none;
        }
        .react-datepicker__month-container {
            width: 100%;
            .react-datepicker__header {
                background-color: #b7d4ff;
                border-radius: 0 !important;
            }
        }
    }
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-color: #fff !important;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 9px !important;
    width: 9px;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
    display: flex !important;
    justify-content: space-evenly !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 30px !important;
    background-color: #004ffa !important;
    color: #fff;
}

.calendar-input-icon {
    position: absolute;
    z-index: 9;
    right: 10px;
    top: 10px;
}

#map {
    height: 40vh;
    width: 100%;
    // margin-top: -20px;
    // margin-bottom: -20px;
}
.landing-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.primary-grid {
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 60vh;
    position: relative;
    h1 {
        font-size: 2em;
        padding-right: 1em;
        margin-top: 1.5em;
    }
}

.icon {
    --ionicon-stroke-width: 16px;
}